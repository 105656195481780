// i18next-extract-mark-ns-start products-wave-acoustics

import { WAVE_APPLICATIONS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductWaveAcousticsPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							WAVE is a powerful acoustic modelling tool used by manufacturers worldwide to design and optimize intake and exhaust systems.
							Whether developing a more affordable exhaust system or creating a custom exhaust sound, WAVE's capabilities are unmatched.
							WAVE provides a comprehensive suite of programs encompassing pre-processing, solver capabilities,
							and post-processing functionalities, enabling the simulation of a broad spectrum of acoustic applications:
						</p>
					</Trans>
					{image && (
						<p>
							<GatsbyImage image={image} alt="WAVE Acoustics" />
						</p>
					)}
					<Trans i18nKey="content_2">
						<h3>Applications</h3>
						<ul>
							<li>Intake system component design: Resonators, air cleaner</li>
							<li>Exhaust system component design: Catalysts, multi-pass mufflers, perforated pipes, absorbing materials</li>
							<li>Pass-by-noise tests and Transient noise analysis over the engine speed range</li>
							<li>Prediction of intake and exhaust resonances</li>
							<li>Transmission loss analysis</li>
							<li>Design of engine noise signature using audio output</li>
							<li>Simulate laboratory acoustic experiments: Level difference, Insertion loss </li>
						</ul>
						<h3>Capabilities</h3>
						<ul>
							<li>Pre-processing
								<ul>
									<li>Advanced 3D pre-processors for seamless creation and fully automatic meshing of most complex mufflers,
										airboxes and other components</li>
									<li>Direct import of shell geometry from all leading CAD packages</li>
									<li>Import and repair of STL geometry</li>
								</ul>
							</li>
							<li>Solvers
								<ul>
									<li>Accurate prediction of radiated intake and exhaust noise with source interaction and flow noise effects fully captured</li>
									<li>Moving sources and/or microphones with accurate Doppler shift</li>
									<li>Custom noise transfer functions can be added to predict interior noise</li>
									<li>Linear acoustic analysis</li>
								</ul>
							</li>
							<li>Post-processing
								<ul>
									<li>All common acoustic plots, audio files, frequency/order tracking post-processing outputs</li>
									<li>Full control over data acquisition and signal processing settings</li>
									<li>Audio files for sound quality assessment, accounting for flow noise and Doppler effects</li>
									<li>Frequency network view of system pressure nodes and anti-nodes</li>
								</ul>
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{WAVE_APPLICATIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductWaveAcousticsPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-wave-acoustics", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}	
	banner: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE.png" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE_Acoustics.png" }) {
		...imageBreaker
	}
}
`;

